import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTrans } from '../../i18n/withTrans';
import {
  Card,
  CardContent,
  IconButton,
  Input,
  Tooltip
} from '@material-ui/core';
import moment from 'moment';
import { MdAdd, MdClear, MdRemove } from 'react-icons/md';
import useAuthContext from '../../contexts/AuthContext';
import { AiOutlineDelete } from 'react-icons/all';

const isBrowser = typeof window !== 'undefined';

const CartList = ({
  t,
  purpose,
  reservationState,
  reservationDispatch,
  setTicketImg,
  setVisibility,
  subtractNbVisitors,
  addNbVisitors,
  onDeleteTicket,
  styles,
  ...props
}) => {
  const { dispatchAPI } = useAuthContext();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  let full_price = '0';
  let reduced_price = '0';
  let child_price = '0';
  let free_price = '0';

  if (isBrowser) {
    full_price = localStorage.getItem('full_price');
    reduced_price = localStorage.getItem('reduced_price');
    child_price = localStorage.getItem('child_price');
    free_price = localStorage.getItem('free_price');
  }
  const totalVisitor =
    JSON.parse(full_price) + JSON.parse(reduced_price) + JSON.parse(free_price);

  const checkDisable = (type, status) =>
    !(
      type === 'free_price' ||
      status === 'old' ||
      JSON.parse(full_price) +
        JSON.parse(reduced_price) +
        JSON.parse(child_price) >
        1
    );

  const onCancelPanier = async (ligne) => {
    try {
      await dispatchAPI('POST', {
        url: `/gts/sup-panier`,
        body: { ligne: ligne }
      });
      reservationDispatch({ lignePanier: '' });
    } catch (e) {
      if (e.response) console.error(e.response.status);
    }
  };

  const clearBasket = () => {
    if (reservationState?.lignePanierList.length) {
      (reservationState?.lignePanierList || []).forEach((el) => {
        (async () => {
          await onCancelPanier(el.lignePanier);
        })();
      });
    }
    if (
      reservationState?.oldReservation &&
      (reservationState?.oldReservation?.lignePanierList || []).length
    ) {
      (reservationState?.oldReservation?.lignePanierList || []).forEach(
        (el) => {
          (async () => {
            await onCancelPanier(el.lignePanier);
          })();
        }
      );
      localStorage.removeItem('panier');
    }
    reservationDispatch({ oldReservation: {} });
  };

  const ticketRender = (ticket) => {
    return (
      <Card
        key={ticket.visitorType}
        className="cart-ticket"
        onMouseEnter={() => setVisibility('visible')}
        onMouseLeave={() => setVisibility('hidden')}
        style={{
          borderColor: reservationState.activeStep === 3 ? '#fff' : '#000'
        }}
      >
        <CardContent>
          <div>
            <img
              src={setTicketImg(ticket.visitorType)}
              alt={`Billet ${ticket.visitorType}`}
            />
          </div>
          <div>
            <p className="ticket-title">
              {t('landingPage.title2')} {t('landingPage.title3')}
            </p>
            <p className="ticket-visit-type">
              {t(`visitTypeInfos.${ticket.visitType}.title`)}
            </p>
            <p className="ticket-date">
              {moment(ticket.date)
                .locale(props.language)
                .format('dddd DD MMMM')
                .toLowerCase()}{' '}
              -{' '}
              {`${ticket.heure.substring(0, 2)}h${ticket.heure.substring(
                3,
                5
              )}`}
            </p>
            <p className="ticket-language">
              {ticket.language === 'F'
                ? `${t('reservationPage.languageChoice.french')} `
                : ticket.language === 'G'
                ? `${t('reservationPage.languageChoice.english')} `
                : `${t('reservationPage.languageChoice.spanish')} `}
            </p>
            <div>
              {reservationState.activeStep === 0 && ticket.status !== 'old' ? (
                <div className="ticket-visitor">
                  <IconButton
                    disabled={checkDisable(ticket.visitorType)}
                    onClick={() => subtractNbVisitors(ticket.visitorType)}
                    style={{
                      opacity: checkDisable(ticket.visitorType) ? 0.4 : 1,
                      cursor: checkDisable(ticket.visitorType)
                        ? 'not-allowed'
                        : 'pointer'
                    }}
                  >
                    <MdRemove />
                  </IconButton>
                  <Input
                    style={{ width: 24 }}
                    value={
                      ticket.visitorType === 'full_price'
                        ? full_price
                        : ticket.visitorType === 'reduced_price'
                        ? reduced_price
                        : ticket.visitorType === 'child_price'
                        ? child_price
                        : free_price
                    }
                    disableUnderline
                  />
                  <IconButton
                    disabled={
                      reservationState.selectedSlot.jauge === totalVisitor
                    }
                    onClick={() => addNbVisitors(ticket.visitorType)}
                    style={{
                      opacity:
                        reservationState.selectedSlot.jauge === totalVisitor
                          ? 0.4
                          : 1,
                      cursor:
                        reservationState.selectedSlot.jauge === totalVisitor
                          ? 'not-allowed'
                          : 'pointer'
                    }}
                  >
                    <MdAdd />
                  </IconButton>
                  <span>
                    {ticket.visitorType === 'full_price'
                      ? t('cart.visitor.full_price')
                      : ticket.visitorType === 'reduced_price'
                      ? t('cart.visitor.reduced_price')
                      : ticket.visitorType === 'child_price'
                      ? t('cart.visitor.child_price')
                      : t('cart.visitor.free_price')}
                  </span>
                </div>
              ) : ticket.status !== 'old' ? (
                <p className="payment-cart-visitor">
                  {ticket.visitorType === 'full_price'
                    ? full_price
                    : ticket.visitorType === 'reduced_price'
                    ? reduced_price
                    : ticket.visitorType === 'child_price'
                    ? child_price
                    : free_price}{' '}
                  {ticket.visitorType === 'full_price'
                    ? t('cart.visitor.full_price')
                    : ticket.visitorType === 'reduced_price'
                    ? t('cart.visitor.reduced_price')
                    : ticket.visitorType === 'child_price'
                    ? t('cart.visitor.child_price')
                    : t('cart.visitor.free_price')}
                </p>
              ) : (
                <p className="payment-cart-visitor">
                  {ticket.visitorType === 'full_price'
                    ? reservationState.oldReservation.full_price
                    : ticket.visitorType === 'reduced_price'
                    ? reservationState.oldReservation.reduced_price
                    : ticket.visitorType === 'child_price'
                    ? reservationState.oldReservation.child_price
                    : reservationState.oldReservation.free_price}{' '}
                  {ticket.visitorType === 'full_price'
                    ? t('cart.visitor.full_price')
                    : ticket.visitorType === 'reduced_price'
                    ? t('cart.visitor.reduced_price')
                    : ticket.visitorType === 'child_price'
                    ? t('cart.visitor.child_price')
                    : t('cart.visitor.free_price')}
                </p>
              )}
              <p className="ticket-price">{ticket.price.toFixed(2)} €</p>
            </div>
          </div>
          {reservationState.activeStep === 0 && (
            <span>
              <IconButton
                className="delete-ticket"
                style={{
                  ...styles,
                  display: checkDisable(ticket.visitorType, ticket.status)
                    ? 'none'
                    : ''
                }}
                disabled={checkDisable(ticket.visitorType, ticket.status)}
                onClick={() =>
                  onDeleteTicket(ticket.visitorType, ticket.status)
                }
              >
                <MdClear />
              </IconButton>
            </span>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Card
        className="cart-wrapper"
        style={{
          borderColor: reservationState.activeStep === 3 ? '#fff' : '#000'
        }}
      >
        {reservationState.ticketsList || reservationState.oldReservation ? (
          <>
            <div className="cart-ticket-list">
              {(reservationState?.oldReservation?.ticketsList || []).map(
                (ticket) => ticketRender(ticket)
              )}
              {(reservationState?.ticketsList || []).map((ticket) =>
                ticketRender(ticket)
              )}
            </div>
            <div className="drawer-btn-wrapper">
              {reservationState.oldReservation && (
                <>
                  {purpose === 'drawer' && (
                    <Tooltip
                      leaveDelay={200}
                      title={t('buttons.cancelCart')}
                      open={tooltipOpen}
                      onOpen={() => setTooltipOpen(true)}
                      onClose={() => setTooltipOpen(false)}
                    >
                      <span
                        onClick={() => clearBasket()}
                        style={{ fontSize: 28 }}
                      >
                        <AiOutlineDelete />
                      </span>
                    </Tooltip>
                  )}
                </>
              )}
            </div>
            <div
              className="cart-total-price"
              style={{
                color:
                  reservationState.activeStep === 3
                    ? '#fff'
                    : 'rgba(0, 0, 0, 0.8)'
              }}
            >
              <p>
                <span>{t('cart.total')}:</span>
                <span>{reservationState.totalPrice} €</span>
              </p>
              <span
                style={{
                  color:
                    reservationState.activeStep === 3
                      ? 'rgba(255, 255, 255, 0.8)'
                      : 'rgba(0, 0, 0, 0.8)'
                }}
              >
                {t('cart.vat-included')}
              </span>
            </div>
          </>
        ) : (
          <div className="empty-cart">
            <p
              style={{
                fontSize: 16,
                fontWeight: 'normal',
                color: 'rgba(0, 0, 0, 0.8)'
              }}
            >
              {t('cart.empty.title')}
            </p>
            <p
              style={{
                fontSize: 16,
                fontWeight: 'normal',
                color: 'rgba(0, 0, 0, 0.8)'
              }}
            >
              {t('cart.empty.subtitle')}
            </p>
          </div>
        )}
      </Card>
    </>
  );
};

export default withTrans(CartList);

CartList.propTypes = {
  t: PropTypes.func.isRequired,
  purpose: PropTypes.string,
  reservationState: PropTypes.shape({
    activeStep: PropTypes.number,
    totalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedSlot: PropTypes.shape({
      jauge: PropTypes.number
    }),
    lignePanierList: PropTypes.arrayOf(PropTypes.shape({})),
    ticketsList: PropTypes.arrayOf(
      PropTypes.shape({
        visitorType: PropTypes.string,
        date: PropTypes.string,
        heure: PropTypes.string,
        language: PropTypes.string,
        status: PropTypes.string,
        price: PropTypes.number
      })
    ),
    oldReservation: PropTypes.shape({
      ticketsList: PropTypes.arrayOf(
        PropTypes.shape({
          visitorType: PropTypes.string,
          status: PropTypes.string,
          price: PropTypes.number
        })
      ),
      lignePanierList: PropTypes.arrayOf(PropTypes.shape({})),
      full_price: PropTypes.string,
      reduced_price: PropTypes.string,
      child_price: PropTypes.string,
      free_price: PropTypes.string
    })
  }).isRequired,
  reservationDispatch: PropTypes.func.isRequired,
  onHandleVisitorNbChange: PropTypes.func,
  setTicketImg: PropTypes.func.isRequired,
  setVisibility: PropTypes.func,
  subtractNbVisitors: PropTypes.func,
  addNbVisitors: PropTypes.func,
  onDeleteTicket: PropTypes.func,
  styles: PropTypes.object,
  language: PropTypes.string
};

CartList.defaultProps = {
  setVisibility: undefined,
  subtractNbVisitors: undefined,
  addNbVisitors: undefined,
  onDeleteTicket: undefined,
  purpose: undefined
};
