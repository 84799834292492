import React, { useState } from "react";
import { Link } from "gatsby";
import { IconButton } from "@material-ui/core";
import opera_logo_white from "assets/images/opera-logowhite.png";
import manatour_logo_white from "assets/images/manatour-logowhite.png";
import opera_logo_black from "assets/images/opera-logoblack.png";
import manatour_logo_black from "assets/images/manatour-logoblack.png";
import { AiOutlineGlobal, AiOutlineUser } from "react-icons/ai";
import { CartIcon } from "../../assets/customIcons";
import LanguageMenu from "../Translation/LanguageDropdownMenu";
import { useHandleResize } from "../../utils";
import { Button } from "@material-ui/core";
import ContactFormModal from "./ContactFormModal";
import CartListDrawer from "../SlotSelection/CartListDrawer";

const Header = ({ t, pageName, indexStep }) => {
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  //   const handleClick = () => {
  //     setOpen(!open);
  //   };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const setLogoOpera = (pageName) => {
    let logoOpera = "";
    switch (pageName) {
      case "home":
        logoOpera = opera_logo_white;
        break;
      case "reservation_page":
        logoOpera = opera_logo_white;
        break;
      case "slot_selection":
        {
          if (indexStep === 2 || indexStep === 3) {
            logoOpera = opera_logo_white;
          } else {
            logoOpera = opera_logo_black;
          }
        }
        break;
      default:
        logoOpera = opera_logo_black;
        break;
    }
    return logoOpera;
  };

  const setLogoManatour = (pageName) => {
    let logoManatour = "";
    switch (pageName) {
      case "home":
        logoManatour = manatour_logo_white;
        break;
      case "reservation_page":
        logoManatour = manatour_logo_white;
        break;
      case "slot_selection":
        {
          if (indexStep === 2 || indexStep === 3) {
            logoManatour = manatour_logo_white;
          } else {
            logoManatour = manatour_logo_black;
          }
        }
        break;
      default:
        logoManatour = manatour_logo_black;
        break;
    }
    return logoManatour;
  };

  const setColor = (pageName) => {
    let color = "";
    switch (pageName) {
      case "home":
        color = "#fff";
        break;
      case "reservation_page":
        color = "#fff";
        break;
      case "slot_selection":
        {
          if (indexStep === 2 || indexStep === 3) {
            color = "#fff";
          } else {
            color = "#000";
          }
        }
        break;
      default:
        color = "#000";
        break;
    }
    return color;
  };

  const { width } = useHandleResize();

  return (
    <header
      style={{ color: setColor(pageName), borderColor: setColor(pageName) }}
    >
      {/*<ContactFormModal open={openForm} setOpen={setOpenForm} />*/}
      <CartListDrawer open={openDrawer} setOpen={setOpenDrawer} />
      <Link to={"/"}>
        <div className="header_logo">
          <img
            className="opera_logo"
            src={setLogoOpera(pageName)}
            alt="logo opera"
          />
          <img
            className="manatour_logo"
            src={setLogoManatour(pageName)}
            alt="manatour logo"
          />
        </div>
      </Link>
      <div className="header_icons">
        {/*<Button onClick={() => handleOpenForm()} style={{color: setColor(pageName), display: "flex", alignItems: "center", marginRight: 5}}><span>Contact</span> </Button>*/}
        <IconButton
          onClick={() => handleOpenDrawer()}
          style={{ color: setColor(pageName) }}
        >
          <CartIcon />
        </IconButton>
        {/* <AiOutlineGlobal
          className="translation_icon"
          onClick={(event) => handleClick(event)}
        /> */}
        <LanguageMenu
          open={open}
          handleClose={handleClose}
          handleOpen={handleOpen}
          pageName={pageName}
          indexStep={indexStep}
        />
        {/* <AiOutlineUser className="user_icon" /> */}
        {pageName === "slot_selection" && indexStep !== 2 && width > 992 && (
          <div
            style={{
              minWidth: 420,
              maxWidth: 420,
              margin: "0 -24px 0 24px",
              borderLeft: "1px solid #000",
              borderColor: setColor(pageName),
              boxSizing: "border-box",
              overflow: "hidden",
            }}
          >
            {indexStep === 3 ? (
              <h2 className="cart-title">{t("myOrderSocket_final")}</h2>
            ) : (
              <h2 className="cart-title">{t("myOrderSocket")}</h2>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
