import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTrans } from '../../i18n/withTrans';
import Container from 'components/Layout/Container';
import CopyrightModal from './CopyrightModal';
import CookiesModal from './CookiesModal';
import ContactFormModal from './ContactFormModal';
import { navigate } from 'gatsby';
import ModalGroup from './ModalGroupPrices';

const isBrowser = typeof window !== 'undefined';

const Footer = ({ t, indexStep, pageName }) => {
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openCookies, setOpenCookies] = useState(false);
  const [openModalPrices, setOpenModalPrices] = useState(false);

  let isCookiesAccepted = false;
  if (isBrowser) {
    isCookiesAccepted = JSON.parse(localStorage.getItem('isCookiesAccepted'));
  }

  useEffect(() => {
    if (!isCookiesAccepted) {
      setOpenCookies(!openCookies);
    }
  }, [isCookiesAccepted]);

  const openCopyrightModal = () => {
    setOpen(true);
  };

  const handleOpenForm = () => {
    setOpenForm(!openForm);
  };

  const handleOpenGroupModal = () => {
    setOpenModalPrices(!openModalPrices);
  };

  const handleOpenCookies = () => {
    setOpenCookies(!openCookies);
  };

  const setColor = (pageName) => {
    let color = '';
    switch (pageName) {
      case 'home':
        color = '#fff';
        break;
      case 'reservation_page':
        color = '#fff';
        break;
      case 'slot_selection':
        {
          if (indexStep === 2 || indexStep === 3) {
            color = '#fff';
          } else {
            color = '#000';
          }
        }
        break;
      default:
        color = '#000';
        break;
    }
    return color;
  };

  return (
    <footer
      style={{ color: setColor(pageName), borderColor: setColor(pageName) }}
    >
      <Container>
        <ContactFormModal open={openForm} setOpen={setOpenForm} />
        <p>
          <CookiesModal open={openCookies} setOpen={setOpenCookies} />
          <ModalGroup open={openModalPrices} setOpen={setOpenModalPrices} />
          <CopyrightModal open={open} setOpen={setOpen} />
          <span>
            <span
              className="copyright-link"
              style={{ float: 'left', marginRight: 10 }}
              onClick={() => navigate('/cgv/')}
            >
              {t('footer.cgv')}
            </span>
            <span
              onClick={() => handleOpenGroupModal()}
              className="contact-link"
              style={{ color: setColor(pageName) }}
            >
              {t('footer.groupPrices')}
            </span>
            <span
              onClick={() => handleOpenForm()}
              className="contact-link"
              style={{ color: setColor(pageName) }}
            >
              Contact
            </span>
          </span>
          <span>
            <span
              onClick={() => handleOpenCookies()}
              className="copyright-link"
              style={{ color: setColor(pageName), marginRight: 10 }}
            >
              Cookies
            </span>
            <span
              className="copyright-link"
              style={{ marginRight: 10 }}
              onClick={() => navigate('/rgpd/')}
            >
              {t('footer.rgpd')}
            </span>
            <span
              onClick={() => openCopyrightModal()}
              className="copyright-link"
            >
              Copyright{' '}
            </span>
            &copy; {new Date().getFullYear()} Manatour
          </span>
        </p>
      </Container>
    </footer>
  );
};

export default withTrans(Footer);

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  indexStep: PropTypes.number.isRequired,
  pageName: PropTypes.string.isRequired
};
