import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withTrans } from '../../i18n/withTrans';
import landingBackground from '../../assets/images/landing-bg_1.jpg';
import step1Background from '../../assets/images/step-1_bg.jpg';
import step3Background from '../../assets/images/step-3_bg.jpg';
import 'assets/stylesheets/application.scss';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';

const Layout = ({ children, pageName, t, indexStep }) => {
  let className = '';
  if (pageName) {
    className = `${className} page-${pageName}`;
  }

  const setBackground = (pageName) => {
    let background = '';
    switch (pageName) {
      case 'home':
        background = `url(${landingBackground}) no-repeat`;
        break;
      case 'reservation_page':
        background = `url(${step1Background}) no-repeat`;
        break;
      case 'slot_selection':
        {
          if (indexStep === 2 || indexStep === 3) {
            background = `url(${step3Background}) no-repeat`;
          } else {
            background = '#fff';
          }
        }
        break;
      default:
        background = '#fff';
        break;
    }
    return background;
  };

  return (
    <>
      <Helmet bodyAttributes={{ class: className }}>
        <title>Gatsby Site</title>
      </Helmet>
      <div
        className="wrapper"
        style={{ background: setBackground(pageName), backgroundSize: 'cover' }}
      >
        <Header indexStep={indexStep} pageName={pageName} t={t} />
        <main>{children}</main>
        <Footer indexStep={indexStep} pageName={pageName} t={t} />
      </div>
    </>
  );
};

export default withTrans(Layout);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  indexStep: PropTypes.number
};

Layout.defaultProps = {
  indexStep: 0
};
