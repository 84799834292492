import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Modal } from '@material-ui/core';
import { MdClear } from 'react-icons/md';
import { withTrans } from '../../i18n/withTrans';
import { useHandleResize } from '../../utils';

const ModalGroup = ({ t, open, setOpen }) => {
  const { width, height } = useHandleResize();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width < 920 ? '95vw' : '870px',
    maxWidth: width < 920 ? '95vw' : '870px',
    height: height < 500 ? '95vh' : 'fit-content',
    maxHeight: height < 500 ? '95vh' : 'fit-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 2,
    pb: 3,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    padding: width < 400 ? '24px 16px' : 30,
    boxSizing: 'border-box',
    overflowX: 'hidden',
    overflowY: 'auto',
    outline: 0,
    outlineStyle: 'none'
  };

  const onClose = () => {
    setOpen(!open);
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={() => onClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      className="group-prices-modal"
    >
      <div style={style}>
        <h2 className="modal-title">{t('footer.groupPrices')}</h2>
        <span style={{ fontStyle: 'italic', fontSize: 14 }}>
          {t('groupModal.subTitle2')}
        </span>
        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          <IconButton onClick={onClose}>
            <MdClear />
          </IconButton>
        </div>
        <div>
          <div className="table-title-group-prices group-title-rotate">
            <p style={{ fontWeight: 'bold' }}>{t('groupModal.title')}</p>
            <span style={{ fontSize: 14, fontStyle: 'italic' }}>
              {t('groupModal.subTitle')}
            </span>
          </div>
          <table className="group-prices-table">
            <thead>
              <tr>
                <th></th>
                <th className="table-title-group-prices">
                  {t('groupModal.prices')}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('groupModal.fullPrice.title')}</td>
                <td>{t('groupModal.fullPrice.price')}</td>
              </tr>
              <tr>
                <td>
                  {t('groupModal.reducedPrice.title')} <br />
                  <span>{t('groupModal.reducedPriceDescription')}</span>
                </td>
                <td>{t('groupModal.reducedPrice.price')}</td>
              </tr>
              <tr>
                <td>{t('groupModal.childPrice.title')}</td>
                <td>{t('groupModal.childPrice.price')}</td>
              </tr>
              <tr>
                <td>
                  {t('groupModal.nightPrice.title')} <br />
                  <span>{t('groupModal.nightPriceDescription')}</span>
                </td>
                <td>{t('groupModal.nightPrice.price')}</td>
              </tr>
            </tbody>
          </table>
          <span
            style={{
              display: 'block',
              fontWeight: 'bold',
              position: 'relative',
              top: '35px',
              marginBottom: '25px'
            }}
          >
            {t('groupModal.professionalInfo')}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default withTrans(ModalGroup);

ModalGroup.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired
};

ModalGroup.defaultProps = {
  open: undefined
};
